import { ALMOST_WHITE, BLACK, DARK_GRAY, LIGHT_GRAY } from "shared/constants"

export const darkTheme = {
  background: BLACK,
  elementBg: DARK_GRAY,
  elementFg: LIGHT_GRAY,
  foreground: ALMOST_WHITE
}

export const lightTheme = {
  background: ALMOST_WHITE,
  elementBg: LIGHT_GRAY,
  elementFg: DARK_GRAY,
  foreground: BLACK
}
