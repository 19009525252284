import { FC } from 'react'
import styled from 'styled-components/macro'

interface Props {
  label: string
  checked: boolean
  onChange: () => void
}
export const SettingsOption: FC<Props> = ({ label, checked, onChange }) => (
  <StyledLabel>
    <span>{label}</span>
    <StyledCheckbox
      checked={checked}
      onChange={onChange}
    />
  </StyledLabel>
)

const StyledCheckbox = styled.input.attrs(() => ({
  type: 'checkbox'
}))`
  width: 3rem;
  aspect-ratio: 1;
`

export const StyledLabel = styled.label`
  display: grid;
  font-size: 2.5rem;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: max-content min-content;
  /* column-gap: 2rem; */
  width: 100%;
  flex: 1 0 auto;
`
