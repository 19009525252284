import { DayData, MockDayData, UserStatsData } from 'shared/types'
import {
  getAverageMeditationPerDay,
  getPseudoDayData
} from './user-stats.utils'

// TODO: refactor this method
// eslint-disable-next-line max-statements
export function getForesightDaysData(
  daysData: DayData[],
  stats: UserStatsData,
  additionalDataLength: number
) {
  const lastData = daysData[daysData.length - 1]

  const averageMeditationDuration = getAverageMeditationPerDay(
    stats.firstMeditationDate,
    stats.totalDuration
  )

  const additionalDaysData: MockDayData[] = new Array(additionalDataLength)
    .fill(null)
    .map((_, i) =>
      getPseudoDayData(i, lastData?.timestamp, averageMeditationDuration)
    )

  return additionalDaysData
}
