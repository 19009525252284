import { StatsChart } from './components/stats-chart/stats-chart.component'
import { StatsNumbers } from './components/stats-numbers/stats-numbers.component'
import { subMonths } from 'date-fns'
import { User } from '@firebase/auth'
import { FC, useMemo } from 'react'
import { useAppSelector } from 'app/store'
import { getUserChartData } from './user-stats.utils'
import {
  selectDaysData,
  selectIsLoading,
  selectUserStats
} from './store/user-stats.selectors'
import { StyledSpinner } from 'shared/components/styled-spinner.styles'
import { NoUserStatsMessage } from './components/no-user-stats-message'
import {
  StatsWithChartWrapper,
  StyledChartRangeSwitcher,
  StyledWrapper
} from './user-stats.styles'
// import { selectDateRange } from 'features/settings/settings.selectors'
import { ChartRange } from 'features/settings/settings.types'

const threeMonthsBeforeDate = subMonths(new Date(), 3)

// TODO: rename to StatisticsPage
// eslint-disable-next-line max-statements
export const UserStats: React.FC<Props> = ({ user, authLoading }) => {
  const userStatistics = useAppSelector(selectUserStats)
  const userDaysData = useAppSelector(selectDaysData)
  const isLoading = useAppSelector(selectIsLoading)
  // const dateRange = useAppSelector(selectDateRange)

  const daysDataByDateRange = useMemo(() => {
    // if (dateRange === ChartRange.AllTime) return userDaysData
    return userDaysData

    // const startDate = threeMonthsBeforeDate
    // const daysData = userDaysData.filter(
    //   dayData => dayData.timestamp >= startDate.getTime()
    // )
    // return daysData
    // }, [dateRange, userDaysData])
  }, [userDaysData])

  const statsByDateRange = useMemo(() => {
    if (!userStatistics) return null
    // if (dateRange === ChartRange.AllTime) return userStatistics

    return userStatistics

    // const { totalDuration, count } = countStats(daysDataByDateRange)
    // const stats: UserStatsData = {
    //   ...userStatistics,
    //   firstMeditationDate: threeMonthsBeforeDate.getTime(),
    //   userId: user?.uid || 'no-user',
    //   totalDuration,
    //   count
    // }

    // return stats
    // }, [dateRange, daysDataByDateRange, user?.uid, userStatistics])
  }, [userStatistics])

  const [userChartData, foresightData, accumulatedDuration] = useMemo(() => {
    if (!statsByDateRange || !userDaysData) return [[], [], []]

    return getUserChartData(daysDataByDateRange, statsByDateRange)
  }, [daysDataByDateRange, statsByDateRange, userDaysData])

  const isChartDataExist = userChartData?.length !== 0

  return (
    <StyledWrapper>
      {authLoading || isLoading ?
        <StyledSpinner />
      : isChartDataExist ?
        <StatsWithChartWrapper>
          <StatsNumbers statsData={statsByDateRange} />

          <StatsChart
            chartData={userChartData}
            foresightData={foresightData}
            accumulatedDurations={accumulatedDuration}
          />

          {/* <ChartRangeSwitcher dateRange={dateRange} /> */}
        </StatsWithChartWrapper>
      : <NoUserStatsMessage />}

      {/* // NOTE: FOR STATS DEBUGGING */}
      {/* {userStatistics && (
        <button
        onClick={() =>
          countStats(userChartData?.[0].data || [], userStatistics)
        }
        >
        Count Stats
        </button>
      )} */}
    </StyledWrapper>
  )
}

interface ChartRangeSwitcherProps {
  dateRange: ChartRange
}
const ChartRangeSwitcher: FC<ChartRangeSwitcherProps> = ({ dateRange }) => {
  // const dispatch = useAppDispatch()
  // const handleDateRangeToggle = () => {
  //   dispatch(settingsActions.toggleDateRange())
  // }

  const dateRangeButtonText =
    dateRange === ChartRange.AllTime ? 'All time' : 'Last 3 months'

  return (
    <StyledChartRangeSwitcher>
      {dateRangeButtonText}

      {/* <StyledDateRangeButton onClick={handleDateRangeToggle}>
        Switch to {dateRange === ChartRange.AllTime ? '3 months' : 'All time'}
      </StyledDateRangeButton> */}
    </StyledChartRangeSwitcher>
  )
}

interface Props {
  user: User | null
  authLoading: boolean
}
