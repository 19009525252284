import { FEATURE_NAME } from 'features/settings/settings.constants'
import { ChartRange, ProgressionType } from 'features/settings/settings.types'
import { User } from 'firebase/auth'
import {
  CollectionReference,
  Firestore,
  collection,
  doc,
  getDoc
} from 'firebase/firestore'

interface SettingsData {
  darkMode: boolean
  timerBlinking: boolean
  sound: boolean
  defaultChartRange: ChartRange
  /** @deprecated */
  progression: ProgressionType
  /** @deprecated */
  progressionRatio: number
}

const DEFAULT_SETTINGS: SettingsData = {
  darkMode: true,
  timerBlinking: true,
  sound: true,
  progression: ProgressionType.Default,
  progressionRatio: 5,
  defaultChartRange: ChartRange.AllTime
}

// eslint-disable-next-line max-statements
export const fetchSettings = async (user: User, firestoreDB: Firestore) => {
  const settingsColRef = collection(
    firestoreDB,
    FEATURE_NAME
  ) as CollectionReference<SettingsData>

  const settingsRef = await doc(settingsColRef, user.uid)
  const settingsData = (await getDoc(settingsRef)).data()

  if (!settingsData) {
    return DEFAULT_SETTINGS
  }

  return settingsData
}
