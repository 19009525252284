import { Timestamp } from 'firebase/firestore'
import { PseudoDayData, ServerUserStatsData, UserStatsData } from 'shared/types'

export const INIT_SERVER_USER_STATS: ServerUserStatsData = {
  firstMeditationDate: Timestamp.fromDate(new Date()),
  totalDuration: 0,
  count: 0,
  userId: '',
  streak: 0
}

export const INIT_USER_STATS: UserStatsData = {
  firstMeditationDate: new Date().getTime(),
  totalDuration: 0,
  count: 0,
  userId: '',
  streak: 0
}

export const INIT_DAY_DATA: PseudoDayData = {
  timestamp: new Date().getTime(),
  count: 0,
  totalDuration: 0,
  meditations: [],
  userId: ''
}
