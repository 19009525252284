import { useAppSelector } from 'app/store'
import { StyledCountdown } from './countdown.styles'
import { CYAN, GREEN, RED, YELLOW } from 'shared/constants'
import { useCountdown } from 'features/home/hooks/use-countdown'
import { useTimerSound } from 'features/home/use-timer-sound'
import useSound from 'use-sound'
import { useEffect } from 'react'
import bellSfx from 'shared/assets/sounds/meditation-bell-sound.mp3'
import styles from './countdown.module.css'
import classnames from 'classnames'

export const Countdown: React.FC<Props> = ({
  seconds,
  checkmarkAnimation = true
}) => {
  const status = useAppSelector(state => state.mainScreen.requestStatus)

  const { blinking, timeRemain } = useCountdown(seconds)
  // useTimerSound(remain)

  // const [playSound] = useSound(bellSfx, {
  //   volume: 0.2
  // })
  // const sound = useAppSelector(state => state.settings.sound)

  // useEffect(() => {
  //   if (remain !== 0) return
  //   console.debug('🚀 ~ useEffect ~ remain:', remain)

  //   if (!sound) return
  //   return playSound()
  // }, [remain, playSound, sound])

  // const countDownColor =
  //   status === 'loaded' ? CYAN
  //   : status === 'error' ? RED
  //   : status === 'loading' ? YELLOW
  //   : 'var(--c-foreground)'

  return (
    <div
      className={classnames(styles.countdown, {
        [styles.countdownAnimation]: blinking,
        [styles.loading]: status === 'loading',
        [styles.error]: status === 'error',
        [styles.loaded]: status === 'loaded'
      })}
    >
      {timeRemain}
    </div>
  )
}

interface Props {
  seconds: number
  checkmarkAnimation?: boolean
}
