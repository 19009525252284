export interface SettingsData {
  darkMode: boolean
  timerBlinking: boolean
  sound: boolean
  // defaultChartRange: ChartRange
  /** @deprecated */
  progression: ProgressionType
  /** @deprecated */
  progressionRatio: number
}

export enum ChartRange {
  AllTime = 'All Time',
  Quarter = 'Quarter'
}

export enum ProgressionType {
  Default = 'default',
  Fibonacci = 'fibonacci',
  Linear = 'linear',
  ByAverage = 'by average'
}

export interface SettingsState extends SettingsData {}
