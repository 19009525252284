import { ProgressionType } from 'features/settings/settings.types'
import {
  EXPONENTIAL_STAGES,
  FIBONACCI_STAGES,
  LINEAR_STAGE_RATIOS,
  SECS_IN_MIN
} from 'shared/constants'
import { getProgressionByAverage } from './get-progression-by-average'
import { Minute } from 'shared/types'

// eslint-disable-next-line complexity
export const getProgressionByType = (
  type: ProgressionType,
  options?: { ratio?: number; average?: number | null }
): Minute[] => {
  const { average, ratio } = options || {}

  if (average && type === ProgressionType.ByAverage)
    return getProgressionByAverage(average ?? 1).map(s => s / SECS_IN_MIN)

  switch (type) {
    case ProgressionType.Default:
      return EXPONENTIAL_STAGES as Minute[]
    case ProgressionType.Fibonacci:
      return FIBONACCI_STAGES
    case ProgressionType.Linear:
      return LINEAR_STAGE_RATIOS.map(num => num * (ratio || 5))
    // case ProgressionType.ByAverage:
    //   return getProgressionByAverage
    default:
      return LINEAR_STAGE_RATIOS.map(num => num * 1)
  }
}
