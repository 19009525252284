import React from "react"
import * as serviceWorkerRegistration from "./app/serviceWorkerRegistration"
import { showAppVersion } from "shared/utils/show-app-version"
import { onServiceWorkerUpdate } from "@3m1/service-worker-updater"
import { BrowserRouter } from "react-router-dom"
import { store } from "./app/store"
import { Provider } from "react-redux"
import { ErrorBoundary } from "app/error-boundary"
import { createRoot } from "react-dom/client"
import { App } from "app/app"

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </ErrorBoundary>
)

showAppVersion()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  // NOTE: from https://github.com/emibcn/service-worker-updater#usage
  onUpdate: onServiceWorkerUpdate
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
