import { ProgressionType } from './settings.types'

export const FEATURE_NAME = 'settings'
export const defaultSettings = {
  darkMode: true,
  timerBlinking: true,
  progression: ProgressionType.Default,
  sound: true,
  progressionRatio: 5
}
export const initialState = defaultSettings
