import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  grid-area: stat-numbers;
  display: grid;
  font-size: 2rem;
  grid-template-areas:
    'foresight foresight foresight'
    'average total count';
  grid-gap: 2rem;
  justify-items: flex-start;
  color: var(--c-gray);
  cursor: default;
  user-select: none;

  @media screen and (hover: hover) {
    font-size: 4rem;
  }

  @media screen and (hover: none) and (orientation: landscape) {
    font-size: 2rem;
    grid-template-areas:
      'foresight'
      'average'
      'total'
      'count';
    margin-bottom: 0;
  }
`

export const StyledStatNumber = styled.span`
  font-size: 3rem;
  line-height: 1;
  color: var(--c-foreground);

  @media screen and (hover: hover) {
    font-size: 4rem;
  }
`

export const StyledStat = styled.span`
  display: flex;
  font-size: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  @media screen and (hover: hover) {
    font-size: 2rem;
  }
`
