import { useLocation } from 'react-router-dom'
import { StyledFooter, StyledFooterLink } from './footer.styles'
import { ReactComponent as AboutIcon } from 'shared/assets/svgs/question.icon.svg'
import { ReactComponent as SettingsIcon } from 'shared/assets/svgs/settings.icon.svg'
import { ReactComponent as TimerIcon } from 'shared/assets/svgs/timer.icon.svg'
import { ReactComponent as BackIcon } from 'shared/assets/svgs/backward.icon.svg'

interface Props {
  isUserExist: boolean
  showLinks?: boolean
  showSwitcher?: boolean
  children?: React.ReactNode
}
// eslint-disable-next-line complexity
export const Footer: React.FC<Props> = ({
  isUserExist,
  showSwitcher = false,
  showLinks = false,
  children
}) => {
  const currentPath = useLocation().pathname
  const aboutIconTo = currentPath === '/about' ? <TimerIcon /> : <AboutIcon />
  const aboutPathTo = currentPath === '/about' ? '/' : '/about'

  const settingsIconTo =
    currentPath === '/settings' ? <TimerIcon /> : <SettingsIcon />
  const settingsPathTo = currentPath === '/settings' ? '/' : '/settings'

  return (
    <StyledFooter>
      {isUserExist ? (
        <>
          {showLinks ? (
            <StyledFooterLink to={aboutPathTo}>{aboutIconTo}</StyledFooterLink>
          ) : null}

          {showSwitcher ? children : null}

          {showLinks ? (
            <StyledFooterLink to={settingsPathTo}>
              {settingsIconTo}
            </StyledFooterLink>
          ) : null}
        </>
      ) : null}
    </StyledFooter>
  )
}
