import { useAppSelector } from 'app/store'
import { startOfToday } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useEffect, useState } from 'react'
import { SECS_IN_MIN } from 'shared/constants'
import { Second, Minute } from 'shared/types'
import {
  getProgressionByType,
  getClosestProgressionDiscrete,
  getNextProgressionStage
} from 'shared/utils'
import { remainTimeToDigitClock } from '../components/countdown/remainTimeToDigitClock'
import { useTimerSound } from '../use-timer-sound'

// eslint-disable-next-line max-statements
export const useCountdown = (seconds: Second) => {
  const [timeRemain, setTimeRemain] = useState('00:00')
  const [secondsRemain, setSecondsRemain] = useState<Second>(0)
  const [minutesRemain, setMinutesRemain] = useState<Minute>(0)
  const [isBlinkingStarted, setIsAnimationEnabled] = useState(false)

  const average = useAppSelector(state => state.userStats.averageDuration)
  const isTimerBlinking = useAppSelector(state => state.settings.timerBlinking)
  const progressionType = useAppSelector(state => state.settings.progression)
  const daysData = useAppSelector(state => state.userStats.daysData)

  const todayPracticeMinutes = useMemo(() => {
    const todayTimestamp = startOfToday().getTime()
    return daysData
      .filter(entry => entry.timestamp >= todayTimestamp)
      .map(entry => entry.totalDuration)
  }, [daysData])

  const progressionSecs = getProgressionByType(progressionType, {
    average
  }).map(n => n * SECS_IN_MIN)

  // WARN:
  // useTimerSound(seconds)

  const convertTimerProgressToCountdown = useCallback(
    // eslint-disable-next-line max-statements
    (seconds: Second) => {
      const todayPractice =
        todayPracticeMinutes.reduce((acc, el) => acc + el, 0) * SECS_IN_MIN

      // useTimerSound(seconds + todayPractice)

      const closestDiscreteStage = getClosestProgressionDiscrete(
        todayPractice + seconds,
        progressionSecs
      )

      const nextProgressionStage = getNextProgressionStage<Second>(
        closestDiscreteStage,
        todayPractice + seconds,
        progressionSecs
      )

      for (const num of progressionSecs) {
        if (closestDiscreteStage !== num) return

        const { seconds: secondsRemain, minutes } = getDayRemainTime(
          nextProgressionStage,
          seconds,
          todayPracticeMinutes
        )

        setSecondsRemain(secondsRemain)
        setMinutesRemain(minutes)
      }
    },
    [progressionSecs, todayPracticeMinutes]
  )

  useEffect(() => {
    if (!seconds) return
    convertTimerProgressToCountdown(seconds)
  }, [seconds, convertTimerProgressToCountdown])

  useEffect(() => {
    if (seconds === 0) {
      setSecondsRemain(0)
      setMinutesRemain(0)
    }

    setTimeRemain(remainTimeToDigitClock(secondsRemain, minutesRemain))
  }, [minutesRemain, seconds, secondsRemain])

  useEffect(() => {
    if (seconds === 0) return
    setIsAnimationEnabled(true)
    return () => setIsAnimationEnabled(false)
  }, [seconds])

  const blinking = isTimerBlinking && isBlinkingStarted

  return { timeRemain, blinking }
}

const getDayRemainTime = (
  nextStage: Second,
  seconds: Second,
  todayPractice: Minute[]
) => {
  const todayPracticeAmount = todayPractice.reduce((acc, el) => acc + el, 0)
  const remainingSeconds =
    nextStage - seconds - todayPracticeAmount * SECS_IN_MIN

  const minutes = Math.floor(remainingSeconds / SECS_IN_MIN)
  const secondsRemainder = Math.floor(remainingSeconds % SECS_IN_MIN)

  return {
    minutes,
    seconds: secondsRemainder
  }
}
