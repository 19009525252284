import { useState, useCallback } from 'react'
import { User } from '@firebase/auth'
import { useAppDispatch, useAppSelector } from 'app/store'
import useNoSleep from 'shared/hooks/use-no-sleep'
import { mainScreenActions } from '../../store/main-screen.slice'
import { setMeditationThunk } from '../../store/main-screen.thunks'
import { TimerButton } from '../timer-button/timer-button.component'
import { Countdown } from '../countdown/countdown.component'
import { useTimerSound } from '../../use-timer-sound'
import { Tips } from '../tips'
import { BottomTextWrapper, Wrapper } from './timer.styles'
import { Second } from 'shared/types'
import { selectIsTimerStarted } from '../../store/main-screen.selectors'

interface Props {
  user: User
  authLoading: boolean
}
// TODO: refactor component
// eslint-disable-next-line max-statements
export const Timer: React.FC<Props> = ({ user, authLoading }) => {
  const dispatch = useAppDispatch()
  const [currentTimerId, setCurrentTimerId] = useState<number | null>(null)
  const [timerDiff, setTimerDiff] = useState<Second>(0)

  const isTimerStarted = useAppSelector(selectIsTimerStarted)

  useNoSleep(isTimerStarted)

  // useTimerSound(timerDiff)

  const finishTimer = useCallback(
    // eslint-disable-next-line max-statements
    async (timerDiff: Second): Promise<void> => {
      const isCurrentTimerIdExist = currentTimerId !== null
      if (!isCurrentTimerIdExist) throw Error('currentTimerId is not exist')

      window.clearInterval(currentTimerId)

      dispatch(mainScreenActions.setTimerStatus(false))
      setTimerDiff(0)

      const isSessionLongerThanMin = timerDiff > MIN_MEDITATION_DURATION
      if (!isSessionLongerThanMin) return

      try {
        // NOTE: line below for fast debugging
        // dispatch(setMeditationThunk({ user, seconds: 61 }))
        dispatch(setMeditationThunk({ user, seconds: timerDiff }))
      } catch (e) {
        console.error(e)
      }
    },
    [currentTimerId, dispatch, user]
  )

  const handleTimer = useCallback((startTime: Second) => {
    const secondsNow = Math.round(Date.now() / 1000)
    const secondsDiff = secondsNow - startTime
    setTimerDiff(secondsDiff)
  }, [])

  const startTimer = useCallback(() => {
    const startInSeconds = Math.round(Date.now() / 1000)
    dispatch(mainScreenActions.setTimerStatus(true))

    const newTimerId = window.setInterval(
      () => handleTimer(startInSeconds),
      100
    )
    setCurrentTimerId(newTimerId)
  }, [dispatch, handleTimer])

  const handleClick = useCallback(() => {
    setTimerDiff(0)

    if (isTimerStarted) {
      return finishTimer(timerDiff)
    } else {
      return startTimer()
    }
  }, [finishTimer, isTimerStarted, startTimer, timerDiff])

  return (
    <Wrapper>
      <TimerButton
        handleTimerClick={handleClick}
        isTimerStarted={isTimerStarted}
        authLoading={authLoading}
      >
        {!authLoading && <Countdown seconds={timerDiff} />}
      </TimerButton>

      <BottomTextWrapper>
        {!authLoading && (
          <Tips
            second={timerDiff}
            isTimerStarted={isTimerStarted}
          />
        )}
      </BottomTextWrapper>
    </Wrapper>
  )
}

const MIN_MEDITATION_DURATION = 10 as Second
