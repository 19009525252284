import { AppRouter } from "app/app-router"
import { auth } from "features/home/firebase-init"
import {
  getChartDataThunk,
  getStatsThunk
} from "features/user-stats/store/user-stats.thunks"
import { useEffect } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useAppDispatch, useAppSelector } from "app/store"
import { ThemeProvider } from "styled-components/macro"
import { GlobalStyles } from "./global-styles"
import { darkTheme, lightTheme } from "./theme"
import { getSettingsThunk } from "features/settings/store/settings.thunks"

export const App = () => {
  const [user] = useAuthState(auth)
  const dispatch = useAppDispatch()
  const darkMode = useAppSelector((state) => state.settings.darkMode)

  useEffect(() => {
    if (!user || user.isAnonymous) return

    dispatch(getSettingsThunk(user))
    dispatch(getChartDataThunk(user))
    dispatch(getStatsThunk(user))
  }, [dispatch, user])

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <GlobalStyles />
      <AppRouter />
    </ThemeProvider>
  )
}
