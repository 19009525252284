import React, { ReactNode } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import SwipeableViews from 'react-swipeable-views'
import { UserStats } from 'features/user-stats/user-stats'
import { User } from 'firebase/auth'
import { SlideRenderProps, virtualize } from 'react-swipeable-views-utils'
import { useAppDispatch, useAppSelector } from 'app/store'
import { mainScreenActions } from './store/main-screen.slice'
import { Header } from './components/header/header.component'
import { Timer } from './components/timer/timer.component'
import { auth } from './firebase-init'
import { Wrapper } from './home.styles'
import { Footer } from '../../shared/components/footer/footer.component'
import { selectIsTimerStarted } from './store/main-screen.selectors'
import { ViewsSwitcher } from './components/views-switcher/views-switcher.component'
import {
  SLIDES_COUNT,
  swipeableViewsContainerStyles,
  swipeableViewsRootStyles
} from './constants'
import { StyledSwipeableView } from 'shared/styles/app.styles'

const { toggleSlideIndex } = mainScreenActions
const VirtualizedSwipeableViews = virtualize(SwipeableViews)

export const Home: React.FC = () => {
  const [user, loading] = useAuthState(auth)
  const dispatch = useAppDispatch()

  const slideIndex = useAppSelector(state => state.mainScreen.slideIndex)
  const dispatchSlideIndex = () => dispatch(toggleSlideIndex())
  const isUserExist = !user?.isAnonymous
  const isTimerStarted = useAppSelector(selectIsTimerStarted)

  return (
    <Wrapper>
      <Header />

      {isUserExist ? (
        <VirtualizedSwipeableViews
          style={swipeableViewsRootStyles}
          containerStyle={swipeableViewsContainerStyles}
          index={slideIndex}
          onChangeIndex={dispatchSlideIndex}
          slideRenderer={slideProps => {
            const reactNode = renderSwipeableSlide(slideProps, user, loading)
            return reactNode(slideProps)
          }}
          slideCount={SLIDES_COUNT}
          enableMouseEvents
          resistance
        />
      ) : (
        <Timer
          user={user as User}
          authLoading={loading}
        />
      )}

      <Footer
        isUserExist={isUserExist}
        showLinks={!isTimerStarted}
        showSwitcher={!isTimerStarted}
      >
        <ViewsSwitcher
          slideIndex={slideIndex}
          slidesCount={2}
          setSlideIndex={dispatchSlideIndex}
        />
      </Footer>
    </Wrapper>
  )
}

function renderSwipeableSlide(
  slideProps: SlideRenderProps,
  user: User | null | undefined,
  loading: boolean
) {
  if (!user) return () => null
  return ({ index, key }: SlideRenderProps): ReactNode => {
    switch (index) {
      case 0:
        return (
          <StyledSwipeableView key={key}>
            <Timer
              user={user}
              authLoading={loading}
            />
          </StyledSwipeableView>
        )

      case 1:
        return (
          <StyledSwipeableView key={key}>
            <UserStats
              authLoading={loading}
              user={user}
            />
          </StyledSwipeableView>
        )
    }
  }
}
