import { Hour, Minute, PokoySession, Second } from 'shared/types'
import { roundToHundredth } from './round-to-nth'
import { SECS_IN_MIN } from 'shared/constants'

export { getFloorProgressionDiscrete as getFloorFibonacciDiscrete } from './get-floor-fibonacci-discrete'
export { roundToHundredth, roundToTenth } from './round-to-nth'
export { showAppVersion } from './show-app-version'
export {
  serverDayDataToStoreAdapter,
  serverStatsDataToStoreAdapter
} from './adapters'
export { getProgressionByType } from './get-progression-by-type'

export const getClosestProgressionDiscrete = (
  seconds: Second,
  progression: Second[]
): Second => {
  const progressionDiscrete = progression.reduce(
    (acc: Second, progressionNum: Second) => {
      const currDiff: Second = Math.abs(progressionNum - seconds)
      const prevDiff: Second = Math.abs(acc - seconds)
      const isCloserToDiscrete = currDiff < prevDiff

      return isCloserToDiscrete ? progressionNum : acc
    },
    Infinity as Second
  )

  return progressionDiscrete
}

export type TimeUnit = Hour | Minute | Second
export function getNextProgressionStage<T extends TimeUnit>(
  currentStage: T,
  timeUnits: T,
  progression: T[]
): T {
  const nextStageIndex = progression.indexOf(currentStage) + 1
  const nextStage =
    currentStage > timeUnits ? currentStage : progression[nextStageIndex]
  return nextStage
}

export function getFloorProgressionDiscrete<T extends TimeUnit>(
  timeUnits: T,
  progression: T[]
) {
  const checkDiscrete = (acc: TimeUnit, fibNum: TimeUnit, index: number) => {
    const curDiff = Math.abs(fibNum - timeUnits) as TimeUnit
    const prevDiff = Math.abs(acc - timeUnits) as TimeUnit
    const isLessThanMinutes = timeUnits >= fibNum
    const isCloserToDiscrete = curDiff < prevDiff
    const closestFib = isCloserToDiscrete ? fibNum : acc

    return isLessThanMinutes ? closestFib : acc
  }

  const fibDiscrete = progression.reduce(checkDiscrete, 0)

  return fibDiscrete
}

export const createSessionData = (
  userId: string,
  seconds: number
): PokoySession => {
  const timestamp = new Date().toISOString()
  const duration = roundToHundredth(seconds / SECS_IN_MIN)

  return {
    userId,
    timestamp,
    duration
  }
}
