import { selectDaysData } from 'features/user-stats/store/user-stats.selectors'
import {
  getAverageMeditationPerDay,
  getAverageCountPerDay,
  // getStreak,
  getTotalInHours
} from 'features/user-stats/user-stats.utils'
import { useEffect, useState } from 'react'
import { UserStatsData } from 'shared/types'
import { useAppDispatch, useAppSelector } from 'app/store'
import { Foresight } from '../foresight/foresight.component'
import { StyledStat, StyledStatNumber, Wrapper } from './stats-numbers.styles'
import { userStatsActions } from 'features/user-stats/store/user-stats.slice'

interface Props {
  statsData: UserStatsData | null
}
export const StatsNumbers: React.FC<Props> = ({ statsData }) => {
  const [totalInHours, setTotalInHours] = useState<number | null>(null)
  const [averageDuration, setAverageDuration] = useState<number | null>(null)
  const [averageCount, setAverageCount] = useState<number | null>(null)
  const userDaysData = useAppSelector(selectDaysData)
  // const [streak, setStreak] = useState<number | null>(null)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!statsData) return

    const averageMeditationPerDay = getAverageMeditationPerDay(
      statsData.firstMeditationDate,
      statsData.totalDuration
    )
    const averageCountPerDay = getAverageCountPerDay(userDaysData)

    dispatch(userStatsActions.setAverageDuration(averageMeditationPerDay))

    setTotalInHours(getTotalInHours(statsData.totalDuration))
    setAverageDuration(averageMeditationPerDay)
    setAverageCount(averageCountPerDay)
    // setStreak(getStreak())
  }, [dispatch, statsData, userDaysData])

  const totalInHoursExist = totalInHours !== null

  return (
    <Wrapper>
      {totalInHoursExist && averageDuration && (
        <Foresight
          totalHours={totalInHours}
          average={averageDuration}
        />
      )}

      {totalInHoursExist && (
        <StyledStat>
          <StyledStatNumber>{totalInHours}</StyledStatNumber>{' '}
          hours&nbsp;in&nbsp;total
        </StyledStat>
      )}

      {averageDuration && (
        <StyledStat>
          <StyledStatNumber>{averageDuration}</StyledStatNumber>
          <span> avg.&nbsp;mins&nbsp;/&nbsp;day </span>
        </StyledStat>
      )}

      {averageCount && (
        <StyledStat>
          <StyledStatNumber>{averageCount}</StyledStatNumber>
          <span> avg.&nbsp;times&nbsp;/&nbsp;day </span>
        </StyledStat>
      )}

      {/* {streak && (
        <StyledStat>
          <StyledStatNumber>{streak}</StyledStatNumber>
          <span> days in a row </span>
        </StyledStat>
      )} */}
    </Wrapper>
  )
}
