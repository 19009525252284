export const SLIDES_COUNT = 2
export const swipeableViewsRootStyles = {
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden'
}
export const swipeableViewsContainerStyles = {
  maxWidth: '100%',
  maxHeight: '100%',
  height: '100%'
}
