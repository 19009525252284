/* eslint-disable max-lines */
import { DAYS_IN_MONTH, MILLIS_IN_DAY, MINS_IN_HOUR } from 'shared/constants'
import { roundToTenth, roundToHundredth } from 'shared/utils/round-to-nth'
import {
  DayData,
  Hour,
  Millisecond,
  Minute,
  MockDayData,
  PokoyChartData,
  UserStatsData
} from 'shared/types'
import {
  INIT_TOTAL_DURATION,
  MAX_DAYS_DATA_LENGTH,
  PRACTICE_HOURS_PROGRESSION,
  THIRD_PART
} from './user-stats.constants'
import { getForesightDaysData } from './get-data'

export const getTotalInHours = (minutes: number): number => {
  return Math.floor(minutes / MINS_IN_HOUR)
}

// TODO: complete the implementation
export const getStreak = () => {
  return 0
}

// eslint-disable-next-line max-statements
export const getAverageMeditationPerDay = (
  firstMeditationDate: Millisecond,
  totalDuration: Minute
) => {
  if (!firstMeditationDate) {
    throw new Error('there are no user statistics yet')
  }

  const statsMillisecondsDiff = Date.now() - firstMeditationDate
  const statsRangeInDays = Math.floor(statsMillisecondsDiff / MILLIS_IN_DAY)
  const average = roundToTenth(totalDuration / statsRangeInDays)

  return average
}

export const getAverageCountPerDay = (dayDataList: DayData[]) => {
  const meditationCount = dayDataList
    .map(dayData => dayData.meditations.length)
    .reduce((acc, meditationCount) => meditationCount + acc, 0)
  const averageMeditationCount = meditationCount / dayDataList.length

  return roundToHundredth(averageMeditationCount)
}

export function getTotalDurationsAsAxisData(
  acc: PokoyChartData[],
  dayData: PokoyChartData,
  index: number
) {
  const prevTotal = acc[index - 1]?.secondary || INIT_TOTAL_DURATION
  const newTotal = dayData.secondary / 60 + prevTotal
  const newData = {
    primary: dayData.primary,
    secondary: roundToHundredth(newTotal)
  }

  return [...acc, newData]
}

// TODO: add generic type for parameters
const cutDataRange = (daysData: (MockDayData | DayData | PokoyChartData)[]) => {
  const dataLength = daysData.length

  if (dataLength <= MAX_DAYS_DATA_LENGTH) {
    return daysData
  }

  const maxLengthDiff = dataLength - MAX_DAYS_DATA_LENGTH
  return daysData.slice(maxLengthDiff, dataLength)
}

export const getPseudoDayData = (
  index: number,
  lastTimestampMillis: number,
  averageMeditationDuration: number
): MockDayData => ({
  timestamp: lastTimestampMillis + (index + 1) * MILLIS_IN_DAY,
  totalDuration: averageMeditationDuration
})

export const getUserChartData = (
  userDaysData: DayData[],
  userStatistics: UserStatsData
) => {
  const foresightChartData =
    getForesightDaysData(userDaysData, userStatistics, DAYS_IN_MONTH) ?? []

  const accumulatedDuration = userDaysData.reduce((acc, day, i) => {
    const prevTotal = acc[i - 1]?.totalDuration || 0
    return [
      ...acc,
      {
        ...day,
        totalDuration: (day?.totalDuration || 0) + prevTotal,
        timestamp: day.timestamp
      }
    ]
  }, [] as DayData[])

  return [userDaysData, foresightChartData, accumulatedDuration] as const
}

export const exportToCSV = (userDaysData: DayData[]) => {
  const csvContent =
    ['timestamp', 'totalDuration', 'count', 'meditations'].join(';') +
    '\n' +
    userDaysData
      .map(day => {
        const dateString = new Date(day.timestamp).toISOString().slice(0, 10)
        const totalDuration = resolveTotalDuration(day)
        const count = resolveCount(day)
        const meditationsString = day.meditations
          .map(meditation => meditation.duration)
          .join(',')

        return [dateString, totalDuration, count, meditationsString].join(';')
      })
      .join('\n')
  const encodedUri = encodeURI('data:text/csv;charset=utf-8,\n' + csvContent)
  return encodedUri
}

function resolveTotalDuration(day: DayData) {
  return (
    !day.totalDuration ?
      day.meditations.length !== 0 ?
        day.meditations.reduce((acc, { duration }) => acc + duration, 0)
      : 0
    : day.count === 0 ?
      day.meditations.reduce((acc, { duration }) => acc + duration, 0)
    : day.totalDuration
  )
}

function resolveCount(day: DayData) {
  return (
    !day.count ?
      day.meditations.length !== 0 ?
        day.meditations.length
      : 0
    : day.totalDuration === 0 ? day.meditations.length
    : day.count
  )
}

export const getMilestoneProgress = (
  nextHoursMilestone: Hour,
  totalHours: Hour
) => {
  const previousMilestoneIndex =
    PRACTICE_HOURS_PROGRESSION.indexOf(nextHoursMilestone) - 1
  const prevHoursMilestone = PRACTICE_HOURS_PROGRESSION[previousMilestoneIndex]
  const currentProgress = totalHours - prevHoursMilestone
  const requiredProgress = nextHoursMilestone - prevHoursMilestone

  return Math.floor((currentProgress / requiredProgress) * 100)
}

// NOTE: fast and dirty for debugging
export const countStats = (
  userDaysData: DayData[],
  userStatistics: UserStatsData
) => {
  if (!userDaysData) return null

  let count = 0

  const totalMeditationTime = userDaysData.reduce((acc, day) => {
    const totalDayDuration = day.meditations.reduce((acc, med) => {
      count += 1
      return acc + med.duration
    }, 0)
    return acc + totalDayDuration
  }, 0)

  console.debug(
    '‼️ ',
    totalMeditationTime + ' / ' + userStatistics?.totalDuration,
    count + ' / ' + userStatistics?.count,
    '\n',
    'average session: ' + totalMeditationTime / count,
    'average day duration: ' + totalMeditationTime / userDaysData.length
  )
}

export const countStatsByDateRange = (daysData: DayData[]) => {
  return daysData.reduce(
    // eslint-disable-next-line complexity
    (acc, day) => {
      const medits = day.meditations
      if (medits.length === 0)
        return {
          totalDuration: acc.totalDuration + day.totalDuration || 0,
          count: acc.count + day.count || 0
        }

      const dayTotalDuration = medits.reduce(
        (acc, m) => acc + m.duration || 0,
        0
      )
      return {
        totalDuration: acc.totalDuration + dayTotalDuration || 0,
        count: acc.count + medits.length
      }
    },
    { count: 0, totalDuration: 0 }
  )
}
