import React, { useState } from 'react'
import { getNextProgressionStage, getProgressionByType } from 'shared/utils'
import {
  StageNumber,
  StageWrapper,
  StyledDesc,
  StyledPercent,
  StyledTip,
  StyledTriangle,
  StyledUnits,
  Wrapper
} from './tips.styles'
import { SECS_IN_MIN } from 'shared/constants'
import { Second } from 'shared/types'
import { useAppSelector } from 'app/store'
import { getTimerProgressToMinutes } from './timer-progress-to-minutes'
import { StyledTooltip } from 'shared/components/styled-tooltip.styles'

interface Props {
  second: Second
  isTimerStarted: boolean
}
// TODO: refactor component
export const Tips: React.FC<Props> = React.memo(
  // eslint-disable-next-line max-statements, complexity
  ({ second, isTimerStarted }) => {
    const [currentStage, setCurrentStage] = useState<Second>(0)
    const progressionType = useAppSelector(state => state.settings.progression)
    const average = useAppSelector(state => state.userStats.averageDuration)

    const progression = getProgressionByType(progressionType, { average }).map(
      s => (s * SECS_IN_MIN) as Second
    )
    const nextStage = getNextProgressionStage<Second>(
      currentStage,
      second,
      progression
    )

    React.useEffect(() => {
      if (second) {
        const currentStage = getTimerProgressToMinutes(second, progression)
        setCurrentStage(currentStage as Second)
      }
    }, [progression, second])

    React.useEffect(() => {
      if (second === 0) {
        setCurrentStage(0)
      }
    }, [currentStage, second])

    const nextStageInMinutes: Second = React.useMemo(
      () => Math.floor((nextStage / SECS_IN_MIN) * 100) / 100,
      [nextStage]
    )

    const timerUnits = React.useMemo(
      () => (nextStageInMinutes === 1 ? 'minute' : 'minutes'),
      [nextStageInMinutes]
    )

    const article = React.useMemo(
      () => (nextStageInMinutes === 1 ? 'is' : 'are'),
      [nextStageInMinutes]
    )

    const percent = (average && second / SECS_IN_MIN / average) ?? 0
    const resultRatio = (percent && percent > 1 && percent.toFixed(1)) || null

    return (
      <Wrapper>
        {isTimerStarted ?
          <>
            <StyledTriangle />

            <StageWrapper>
              <StageNumber>{nextStageInMinutes}</StageNumber>
              <StyledUnits>{timerUnits}</StyledUnits>
              <StyledDesc>{article}&nbsp;up&nbsp;next</StyledDesc>
            </StageWrapper>

            {resultRatio ?
              <StyledPercent>
                <StyledTooltip content='Current meditation multiplier'>
                  x{resultRatio}
                </StyledTooltip>
              </StyledPercent>
            : null}
          </>
        : <StyledTip>Hold the button to start</StyledTip>}
      </Wrapper>
    )
  }
)
