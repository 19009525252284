import { useEffect, useRef } from 'react'
import { DayData, MockDayData } from 'shared/types'
import { IChartApi, UTCTimestamp, createChart } from 'lightweight-charts'
import {
  CHART_OPTIONS,
  MEDITS_SERIES_OPTIONS,
  FORESIGHT_SERIES_OPTIONS,
  ACCUMULATED_SERIES_OPTIONS
} from './constants'
import styles from './stats-chart.module.css'
import { MILLIS_IN_SECOND, DAYS_IN_MONTH } from 'shared/constants'
import { StyledDateRangeButton } from 'features/user-stats/user-stats.styles'
import { getChartRangeParams } from './get-chart-range-params'

interface Props {
  chartData: DayData[]
  foresightData: MockDayData[]
  accumulatedDurations: DayData[]
}
// eslint-disable-next-line max-statements
export const StatsChart: React.FC<Props> = ({
  chartData,
  foresightData,
  accumulatedDurations
}) => {
  // TODO: add light/dark mode for the chart
  // const darkMode = useAppSelector(state => state.settings.darkMode)

  const chartRef = useRef<IChartApi | null>(null)
  const chartContainerRef = useRef<HTMLDivElement | null>(null)

  // eslint-disable-next-line max-statements
  useEffect(() => {
    const handleResize = () =>
      chart.applyOptions({ width: chartContainerRef?.current?.clientWidth })

    if (!chartContainerRef.current) return

    const chart = createChart(chartContainerRef?.current, {
      ...CHART_OPTIONS,
      height: chartContainerRef?.current?.clientHeight,
      // height: 340,
      width: chartContainerRef?.current?.clientWidth,
      autoSize: false
    })
    chartRef.current = chart

    chart.timeScale().fitContent()
    chart.timeScale().setVisibleLogicalRange({
      from: chartData.length - DAYS_IN_MONTH,
      to: chartData.length
    })

    const accumulatedSeries = chart.addAreaSeries(ACCUMULATED_SERIES_OPTIONS)
    // TODO: remove temporal mapping
    const mappedAccumulatedData = accumulatedDurations?.map((d, i) => ({
      value: d.totalDuration,
      time: (d.timestamp / MILLIS_IN_SECOND + i * 0.0001) as UTCTimestamp
    }))

    const foresightSeries = chart.addHistogramSeries(FORESIGHT_SERIES_OPTIONS)
    // TODO: remove temporal mapping
    const mappedForesightData = foresightData?.map((d, i) => ({
      value: d.totalDuration,
      time: (d.timestamp / MILLIS_IN_SECOND + i * 0.0001) as UTCTimestamp
    }))

    const realSeries = chart.addHistogramSeries(MEDITS_SERIES_OPTIONS)
    const mappedRealData =
      chartData?.map((d, i) => ({
        value: d.totalDuration,
        time: (d.timestamp / MILLIS_IN_SECOND + i * 0.0001) as UTCTimestamp
      })) ?? []

    accumulatedSeries.setData(mappedAccumulatedData)
    foresightSeries.setData(mappedForesightData ?? [])
    realSeries.setData(mappedRealData)

    window.addEventListener('resize', handleResize)

    // NOTE: watermark
    chart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(222, 222, 222, 0.1)',
        text: '꩜Pokoy'
      }
    })

    return () => {
      window.removeEventListener('resize', handleResize)
      chart.remove()
    }
  }, [chartData])

  const handleZoomIn = () => {
    const [from, to, increment] = getChartRangeParams(chartRef.current)

    if (!from || !to || !increment) return
    if (from + increment > to - increment) return

    const isGreaterThan = to > increment
    chartRef.current?.timeScale().setVisibleLogicalRange({
      from: isGreaterThan ? from + increment : from,
      to: isGreaterThan ? to - increment : to
    })
  }

  const handleZoomOut = () => {
    const [from, to, increment] = getChartRangeParams(chartRef.current)

    if (!from || !to || !increment) return
    if (from - increment < 10) return

    const isGreaterThan = from > increment
    chartRef.current?.timeScale().setVisibleLogicalRange({
      from: isGreaterThan ? from - increment : from,
      to: isGreaterThan ? to + increment : to
    })
  }
  const handleScrollLeft = () => {
    const [from, to, increment] = getChartRangeParams(chartRef.current)

    if (!from || !to || !increment) return

    chartRef.current?.timeScale().setVisibleLogicalRange({
      from: from - increment,
      to: to - increment
    })
  }
  const handleScrollRight = () => {
    const [from, to, increment] = getChartRangeParams(chartRef.current)

    if (!from || !to || !increment) return

    chartRef.current?.timeScale().setVisibleLogicalRange({
      from: from + increment,
      to: to + increment
    })
  }

  const handleResetScroll = () => {
    chartRef.current?.timeScale().setVisibleLogicalRange({
      from: chartData.length - DAYS_IN_MONTH,
      to: chartData.length
    })
  }

  return (
    <>
      <div className={styles.statsChartRangeButtonsWrapper}>
        <StyledDateRangeButton onClick={handleZoomIn}>++</StyledDateRangeButton>

        <StyledDateRangeButton onClick={handleScrollLeft}>
          {'<<'}
        </StyledDateRangeButton>
        <StyledDateRangeButton onClick={handleResetScroll}>
          {'↺'}
        </StyledDateRangeButton>
        <StyledDateRangeButton onClick={handleScrollRight}>
          {'>>'}
        </StyledDateRangeButton>

        <StyledDateRangeButton onClick={handleZoomOut}>
          --
        </StyledDateRangeButton>
      </div>

      <div className={styles.statsChartWrapper}>
        <div
          ref={chartContainerRef}
          className={styles.statsChart}
        />
      </div>
    </>
  )
}
