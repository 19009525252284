import { Link } from "react-router-dom"
import styled from "styled-components/macro"
import { ReactComponent as LogoWithName } from "shared/assets/svgs/logo-with-name.svg"
import { Footer } from "shared/components/footer/footer.component"

export const About = () => {
  return (
    <AboutPageWrapper>
      <AboutSection>
        <LogoWithName style={{ marginBottom: "1rem", width: "18rem" }} />
        <small style={{ marginBottom: "1rem", color: "var(--c-gray)" }}>
          Meditation for impulsive minds
        </small>

        <p style={{ marginBottom: "0" }}>
          The app is designed to help you meditate for longer periods each day
          by managing your "inner monkey.
        </p>

        <StyledList>
          <li>
            <Link to="how-it-works">How it works →</Link>
          </li>
          <li>
            <Link to="tutorial">Tutorial →</Link>
          </li>
          <li>
            <a
              href="./CHANGELOG.txt"
              target="_blank"
              rel="noopener noreferrer"
            >
              Changelog ↗
            </a>
          </li>
        </StyledList>
      </AboutSection>
      <Footer
        isUserExist={true}
        showSwitcher={false}
        showLinks={true}
      ></Footer>
    </AboutPageWrapper>
  )
}

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  justify-content: start;
  max-width: 66%;
  font-size: 2.5rem;
  margin-top: 4rem;
`

const AboutPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

const AboutSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
`
