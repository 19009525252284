import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FEATURE_NAME } from '../settings.constants'
import { ChartRange, ProgressionType, SettingsState } from '../settings.types'

const initialState: SettingsState = {
  darkMode: true,
  timerBlinking: true,
  progression: ProgressionType.Default,
  sound: true,
  progressionRatio: 5
  // defaultChartRange: ChartRange.AllTime
}

export const settingsSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    toggleDarkMode: state => {
      state.darkMode = !state.darkMode
    },
    toggleTimerBlinking: state => {
      state.timerBlinking = !state.timerBlinking
    },
    toggleSound: state => {
      state.sound = !state.sound
    },
    // toggleDateRange: (state, action: PayloadAction<void>) => {
    //   const newDateRange =
    //     state.defaultChartRange === ChartRange.AllTime
    //       ? ChartRange.Quarter
    //       : ChartRange.AllTime
    //   state.defaultChartRange = newDateRange
    // },
    setProgression: (
      state,
      action: PayloadAction<SettingsState['progression']>
    ) => {
      state.progression = action.payload
    },
    setProgressionRatio: (
      state,
      action: PayloadAction<SettingsState['progressionRatio']>
    ) => {
      state.progressionRatio = action.payload
    },
    // setDefaultChartRange: (
    //   state,
    //   action: PayloadAction<SettingsState['defaultChartRange']>
    // ) => {
    //   state.defaultChartRange = action.payload
    // },
    setSettings: (state, action: PayloadAction<SettingsState>) => {
      state.darkMode = action.payload.darkMode
      state.timerBlinking = action.payload.timerBlinking
      state.sound = action.payload.sound
      state.progression = action.payload.progression
      state.progressionRatio = action.payload.progressionRatio
      // state.defaultChartRange = action.payload.defaultChartRange
    }
  }
})

export const settingsActions = {
  ...settingsSlice.actions
}

export const settingsSliceReducer = settingsSlice.reducer
