import { StyledButton } from 'shared/styles/app.styles'
import styled from 'styled-components/macro'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 5vh 0;
  // margin: 15vh 0;

  @media screen and (width < 768px) {
    padding: 1vh 0;
  }

  @media screen and (orientation: landscape) {
    // margin: 4vh 0;
  }

  @media screen and (pointer: fine) {
    // margin: 10vh 0;
  }
`

export const StatsWithChartWrapper = styled.div`
  min-width: 28rem;
  height: 100%;
  display: grid;
  grid-template-areas:
    'stat-numbers'
    'range-switch'
    'chart';
  grid-template-rows: 1fr min-content 3fr;
  gap: 2.5rem;

  @media screen and (hover: none) and (orientation: landscape) {
    grid-template-areas:
      'stat-numbers range-switch'
      'stat-numbers chart';
    grid-template-columns: 1fr 2fr;
    grid-template-rows: min-content 4fr;
    gap: 2rem;
    align-items: center;
    min-width: auto;
  }
`

export const StyledChartRangeSwitcher = styled.label`
  grid-area: range-switch;
  display: flex;
  gap: 2rem;
  font-weight: 700;
  font-size: 2.5rem;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-self: center;
  margin-top: 0;

  @media screen and (hover: none) and (orientation: landscape) {
    font-size: 1.5rem;
  }
`

export const StyledDateRangeButton = styled(StyledButton)`
  margin: 0 auto;
  font-size: 2rem;
  padding: 0.5rem 1.5rem;
  color: var(--c-foreground);

  &::after {
    display: none;
  }
`
