import styled from 'styled-components/macro'
import { settingsActions } from '../store/settings.slice'
import { useAppDispatch, useAppSelector } from 'app/store'
import {
  EXPONENTIAL_STAGES,
  FIBONACCI_STAGES,
  LINEAR_STAGE_RATIOS
} from 'shared/constants'
import { useCallback } from 'react'
import { ChartRange, ProgressionType } from '../settings.types'
import { StyledButton } from 'shared/components/styled-button.styles'
import { ReactComponent as ExportIcon } from 'shared/assets/svgs/export-icon.svg'
import { exportToCSV } from 'features/user-stats/user-stats.utils'
import { selectDaysData } from 'features/user-stats/store/user-stats.selectors'
import { getProgressionByAverage } from 'shared/utils/get-progression-by-average'
import { SettingsOption, StyledLabel } from './settings-option'
import { StyledTooltip } from 'shared/components/styled-tooltip.styles'

// eslint-disable-next-line max-statements
export const SettingsControls: React.FC = () => {
  const dispatch = useAppDispatch()

  const timerBlinking = useAppSelector(state => state.settings.timerBlinking)
  const sound = useAppSelector(state => state.settings.sound)
  const progression = useAppSelector(state => state.settings.progression)
  const progressionRatio = useAppSelector(
    state => state.settings.progressionRatio
  )
  const darkMode = useAppSelector(state => state.settings.darkMode)
  const daysData = useAppSelector(selectDaysData)
  const average = useAppSelector(state => state.userStats.averageDuration) ?? 1

  const exportToCsvOnClick = useCallback(() => {
    window.open(exportToCSV(daysData))
  }, [daysData])

  const progressionByAverage =
    getProgressionByAverage(average)
      .slice(0, 3)
      .map(n => n / 60)
      .join(', ') + '...'

  return (
    <SettingsWrapper>
      <StyledExportButton
        type='button'
        onClick={exportToCsvOnClick}
        fontSize='2rem'
      >
        <ExportIcon
          color={'currentColor'}
          style={{
            width: '3rem',
            height: '3rem',
            marginRight: '1rem'
          }}
        />
        Statistics Export
      </StyledExportButton>

      {/* <hr style={{ width: "100%" }} /> */}

      <SettingsOption
        label='Dark mode'
        checked={darkMode}
        onChange={() => dispatch(toggleDarkMode())}
      />

      <SettingsOption
        label='Timer blinking'
        checked={timerBlinking}
        onChange={() => dispatch(toggleTimerBlinking())}
      />

      <SettingsOption
        label='Sound'
        checked={sound}
        onChange={() => dispatch(toggleSound())}
      />

      {/* <StyledLabel>
        <span>Chart range</span>
        <StyledSelect
          value={ChartRange.AllTime}
          onChange={({ target: { value } }) =>
            dispatch(setDefaultChartRange(value as ChartRange))
          }
        >
          <option value={ChartRange.AllTime}>All time</option>

          <option value={ChartRange.Quarter}>Quarter</option>
        </StyledSelect>
      </StyledLabel> */}

      <StyledLabel>
        <StyledTooltip content='⚠️ Will be replaced only by progression by average.'>
          <span>Progression type*</span>
        </StyledTooltip>

        <StyledSelect
          value={progression}
          onChange={({ target: { value } }) =>
            dispatch(setProgression(value as ProgressionType))
          }
        >
          {progression === ProgressionType.ByAverage && (
            <option value={ProgressionType.ByAverage}>
              By average*: {progressionByAverage}
            </option>
          )}

          {progression === Default && (
            <option value={Default}>
              Default: {getProgressionString(EXPONENTIAL_STAGES)}
            </option>
          )}

          {progression === Fibonacci && (
            <option value={Fibonacci}>
              Fibonacci: {getProgressionString(FIBONACCI_STAGES)}
            </option>
          )}

          {progression === Linear && (
            <option value={Linear}>
              Linear:{' '}
              {getProgressionString(
                LINEAR_STAGE_RATIOS.map(n => n * progressionRatio)
              )}
            </option>
          )}
        </StyledSelect>
      </StyledLabel>

      {progression === 'linear' ?
        <StyledLabel>
          <span>Progression ratio</span>
          <StyledInput
            type='number'
            min='1'
            max='30'
            value={progressionRatio}
            onChange={e => dispatch(setProgressionRatio(+e.target.value))}
          />
        </StyledLabel>
      : null}
    </SettingsWrapper>
  )
}

const { ByAverage, Default, Fibonacci, Linear } = ProgressionType

const getProgressionString = (progression: number[]): string => {
  return progression.slice(1, 5).join(', ') + '...'
}

const {
  setProgression,
  setProgressionRatio,
  // setDefaultChartRange,
  toggleSound,
  toggleTimerBlinking,
  toggleDarkMode
} = settingsActions

/* eslint-disable max-lines */
const SettingsWrapper = styled.div`
  margin: 10rem 0;
  display: flex;
  row-gap: 2.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 50%;
`

const StyledSelect = styled.select`
  max-width: 20rem;
`

const StyledInput = styled.input`
  max-width: 20rem;
`

const StyledExportButton = styled(StyledButton)`
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
`
