import { DocumentData, DocumentReference, Timestamp } from 'firebase/firestore'
export * from './chart.types'

export interface Coords {
  x: number
  y: number
}

export interface StatsData {
  [date: string]: number[]
}

export interface PokoySession {
  duration: number
  timestamp: DateString
  user?: string | DocumentReference<DocumentData>
  userId: string
}

export type FirestoreRefPath = Flavor<string, 'FirestoreRefPath'>

export interface DayData {
  timestamp: Millisecond
  meditations: PokoySession[]
  userId: string
  statsRef?: FirestoreRefPath
  /** @deprecated */
  totalDuration: number
  /** @deprecated */
  count: number
}

export interface ServerDayData {
  timestamp: Timestamp
  meditations: PokoySession[]
  userId: string
  statsRef: DocumentReference<DocumentData>
  /** @deprecated */
  totalDuration: number
  /** @deprecated */
  count: number
}

export interface MockDayData {
  timestamp: Millisecond
  /** @deprecated */
  totalDuration: number
}

interface Flavoring<FlavorT> {
  _type?: FlavorT
}
export type Flavor<T, FlavorT> = T & Flavoring<FlavorT>

export type Millisecond = Flavor<number, 'Milisecond'>
export type Second = Flavor<number, 'Second'>
export type Minute = Flavor<number, 'Minute'>
export type Hour = Flavor<number, 'Hour'>

export type DateString = Flavor<string, 'DateString'>

interface BasicUserStatsData {
  streak: number
  count: number
  userId: string
  totalDuration: Minute
}

export interface UserStatsData extends BasicUserStatsData {
  firstMeditationDate: Millisecond
}

export interface ServerUserStatsData extends BasicUserStatsData {
  firstMeditationDate: Timestamp
}

export enum RequestStatus {
  NONE,
  REQUEST,
  SUCCESS,
  FAILURE
}

export interface PseudoDayData {
  timestamp: Millisecond
  meditations: PokoySession[]
  userId: string
  /** @deprecated */
  totalDuration: number
  /** @deprecated */
  count: number
}

export interface PseudoServerDayData {
  timestamp: Timestamp
  totalDuration: number
  count: number
  meditations: PokoySession[]
  userId: string
}
